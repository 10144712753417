.clients {
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;

    overflow: hidden;
    margin: 57px 0 0 0;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        overflow: hidden;

        row-gap: 15px;
        column-gap: 45px;

        & img {
            margin-left: 0 !important;
        }
    }

    & img {
        margin-left: 71px;
        vertical-align: middle;
    }
}

@media screen and (max-width: 1480px) {
    .clients {
        margin: 22px 0 30px 0;
    }
}

@media screen and (max-width: 500px) {
    .clients {
        margin: 32px 0 36px 0;
    }
}
