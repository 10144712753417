@import '../../assets/styles/breackpoints.scss';
@import '../../assets/styles/variables.scss';

.connection {
    height: 100vh;
    overflow: hidden;

    background: linear-gradient(
        163.25deg,
        rgba(149, 190, 65, 0.09) 5.09%,
        rgba(149, 190, 65, 0) 55.96%
    );

    background-image: url('../../assets/images/green-arrow2.svg');
    background-size: 82% 85%;
    background-position: top right;
    background-repeat: no-repeat;

    &__wrapper {
        margin-left: 9%;
        margin-right: 7%;
        margin-top: 9%;
    }

    &__content {
        display: grid;
        column-gap: 70px;
        row-gap: 0;

        grid-template-columns: 40% 60%;
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
            'header image'
            'header image'
            'header image'
            'info cards';
    }

    &__header {
        grid-area: header;
        align-self: center;
        width: 65%;

        &-title {
            color: #242424;
            font-family: Raleway;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            margin-bottom: 30px;
        }
    }

    &__info {
        grid-area: info;

        border-radius: 5px;
        border: 2px solid #8cbe26;
        background: #fff;

        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        padding: 45px 18px 20px 20px;

        span {
            display: block;
        }

        &-image {
            margin-top: -80px;
        }
    }

    &__image {
        grid-area: image;
        display: flex;
        align-items: end;
        justify-content: center;
        gap: 20px;
        height: auto;

        &-one {
            height: 500px;
        }

        &-two {
            height: 450px;
        }

        &-wrapper-arrow {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    &__cards {
        grid-area: cards;
        display: flex;
        align-items: stretch;

        li {
            display: block;
            border: 2px solid #e1e1e1;
            background: #fff;

            padding: 42px 34px;
        }

        li:first-child {
            border-radius: 5px 0 0 5px;
            border-right: none;
        }

        li:last-child {
            border-radius: 0 5px 5px 0;
            border-left: none;
        }

        &-title {
            color: $main-green;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 27px;

            margin-bottom: 6px;
        }

        &-text {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }
}

@media screen and (max-width: 1680px) and (min-width: 768px) {
    .connection {
        &__wrapper {
            margin-top: 8%;
        }
    }
}
@media screen and (max-width: 1480px) and (min-width: 768px) {
    .connection {
        background-size: 70% 95%;
        &__wrapper {
            margin-top: 9%;
        }
        &__content {
            column-gap: 40px;

            grid-template-columns: 35% 60%;
        }

        &__header {
            width: 75%;

            &-title {
                font-size: 32px;

                margin-bottom: 20px;
            }
        }

        &__info {
            font-size: 13px;

            padding: 20px 18px 20px 20px;

            &-image {
                display: none;
            }
        }

        &__image {
            gap: 10px;
            height: auto;

            &-one {
                height: 390px;
            }

            &-two {
                height: 340px;
            }
        }

        &__cards {
            li {
                padding: 20px;
            }

            &-text {
                font-size: 13px;
            }
        }
    }
}
@media screen and (max-width: 1231px) and (min-width: 768px) {
}

@media screen and (max-width: 1056px) {
    .connection {
        height: auto;

        &__wrapper {
            margin-bottom: 5%;
        }
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
    .connection {
        background-image: none;

        &__wrapper {
            margin-left: 25px;
            margin-right: 25px;
            margin-top: 32px;
            margin-bottom: 40px;
        }

        &__content {
            column-gap: 0;
            row-gap: 0;

            grid-template-columns: auto;
            grid-template-rows: repeat(4, auto);
            grid-template-areas:
                'header'
                'image'
                'cards'
                'info';
        }

        &__header {
            align-self: self-start;
            width: 100%;

            margin-bottom: 30px;

            &-title {
                font-size: 26px;

                margin-bottom: 16px;
            }
        }

        &__info {
            font-size: 13px;
            font-weight: 400;
            line-height: normal;

            padding: 16px;
            margin: 0 -8px;

            &-image {
                display: none;
            }
        }

        &__image {
            justify-content: center;
            gap: 4px;

            &-one {
                height: 300px;
            }

            &-two {
                height: 250px;
            }

            &-wrapper-arrow {
                width: 8%;

                img {
                    width: 80%;
                }
            }
        }

        &__cards {
            flex-direction: column;
            align-items: stretch;

            margin-bottom: 18px;

            li {
                padding: 16px;
                margin: 0 -8px;
            }

            li:first-child {
                border-radius: 0;
                border-right: 2px solid #e1e1e1;
                border-bottom: none;
            }

            li:last-child {
                border-radius: 0;
                border-left: 2px solid #e1e1e1;
                border-top: none;
            }

            &-title {
                color: $main-green;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 27px;

                margin-bottom: 6px;
            }

            &-text {
                color: #000;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }
}
