@import '../../assets/styles/variables.scss';

.we-do {
    position: relative;
    height: 100vh;
    overflow: hidden;

    background: radial-gradient(
            137.99% 180.65% at 60.79% -38.63%,
            rgba(140, 190, 38, 0.05) 0%,
            rgba(140, 190, 38, 0.05) 100%
        ),
        linear-gradient(
            101deg,
            rgba(203, 245, 118, 0.1) 0%,
            rgba(236, 255, 197, 0) 80.9%
        );

    &__wrapper {
        margin-left: 9%;
        margin-top: 9%;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 15px;

        margin-bottom: 4%;

        &-text {
            color: #242424;
            font-family: Raleway;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        &-image {
            width: 39px;
            height: 39px;
        }
    }

    &__content {
        display: grid;
        gap: 30px;

        grid-template-columns: 28% 55%;
        grid-template-rows: repeat(2, fit-content);
        grid-template-areas:
            'card1 card2'
            'card1 card3';
    }
}

.we-do__card {
    &-header {
        display: flex;
        flex-direction: column;
        gap: 4px;

        margin-bottom: 23px;
    }

    &-title {
        color: $main-green;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 37px;
    }

    &-subtitle {
        color: #000;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
    }

    &-group-text {
        display: flex;
        gap: 18px;
    }

    &-group-app {
        display: flex;
        align-items: center;
        gap: 17px;

        margin-bottom: 26px;
    }

    &-text {
        display: block;
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-bottom: 8px;
    }

    ul &-text::before {
        content: '- ';
        color: #8cbe26;
    }
}

.we-do__card--one {
    grid-area: card1;
    padding: 30px 34px 0 34px;

    img {
        width: 115%;
        margin: -45% auto 0 -15%;
    }
}

.we-do__card--two {
    grid-area: card2;
    display: flex;
    gap: 34px;

    padding: 30px 58px 0 25px;

    .we-do__card-image {
        margin-top: -120px;
    }
}

.we-do__card--three {
    grid-area: card3;
    padding: 30px 48px 30px 34px;
}

@media screen and (max-width: 1680px) and (min-width: 768px) {
    .we-do {
        &__header {
            margin-bottom: 2%;
        }
    }

    .we-do__card {
        &-header {
            display: flex;
            flex-direction: column;
            gap: 4px;

            margin-bottom: 20px;
        }

        &-title {
            font-size: 18px;

            line-height: 25px;
        }

        &-subtitle {
            font-size: 15px;
            line-height: 23px;
        }

        &-group-text {
            display: flex;
            gap: 15px;
        }
    }
}

@media screen and (max-width: 1480px) and (min-width: 768px) {
    .we-do {
        &__content {
            gap: 20px;
            grid-template-columns: 25% 62%;
        }
    }

    .we-do__card--one {
        padding: 15px 16px 0 16px;

        img {
            width: 110%;
            margin: -45% auto 0 -15%;
        }
    }

    .we-do__card--two {
        gap: 28px;

        padding: 15px 30px 0 15px;

        .we-do__card-image {
            width: 190px;
            margin-top: -80px;
        }
    }

    .we-do__card--three {
        grid-area: card3;
        padding: 15px 30px 15px 19px;
    }
}

@media screen and (max-width: 1231px) and (min-width: 768px) {
    .we-do {
        &__card-header {
            margin-bottom: 15px;
        }

        &__content {
            grid-template-columns: 40% 50%;
            grid-template-areas:
                'card1 card2'
                'card3 card3';
        }
    }

    .we-do__card {
        &-group-app {
            margin-bottom: 10px;
            img {
                width: 34px;
                height: 34px;
            }
        }
    }

    .we-do__card--one {
        img {
            display: none;
        }
    }

    .we-do__card--two {
        padding: 20px 48px 0 15px;

        .we-do__card-image {
            display: none;
        }
    }

    .we-do__card--three {
        padding: 20px 38px 20px 24px;
    }
}

@media screen and (max-width: 1056px) {
    .we-do {
        height: auto;

        &__wrapper {
            margin-bottom: 5%;
        }

        &__header {
            &-text {
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
    .we-do {
        &__wrapper {
            margin: 34px 8px 40px 8px;
        }

        &__header {
            gap: 10px;

            margin-bottom: 15px;

            &-text {
                font-size: 28px;
            }

            &-image {
                width: 30px;
                height: 30px;
            }
        }

        &__content {
            gap: 8px;

            grid-template-columns: 100%;
            grid-template-rows: repeat(3, fit-content);
            grid-template-areas:
                'card1'
                'card2'
                'card3';
        }
    }

    .we-do__card {
        &-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            margin-bottom: 16px;
        }

        &-title {
            font-size: 19px;
        }

        &-subtitle {
            font-size: 15px;
        }

        &-group-text {
            flex-direction: column;
            gap: 0;
        }

        &-group-app {
            gap: 8px;

            margin-bottom: 19px;

            img {
                width: 33px;
                height: 33px;
            }
        }

        &-text {
            font-size: 13px;
            margin-bottom: 5px;
        }
    }

    .we-do__card--one {
        display: flex;
        flex-direction: column;
        padding: 24px 17px 0 17px;

        img {
            width: 70%;
            margin: -20% auto 0 auto;
        }
    }

    .we-do__card--two {
        flex-direction: row-reverse;
        align-items: end;
        gap: 34px;

        padding: 24px 20px 0 17px;

        .we-do__card-header {
            width: 200%;
        }

        .we-do__card-image {
            width: 145px;
            height: 220px;
            margin-top: 0;
        }
    }

    .we-do__card--three {
        padding: 24px 17px 18px 17px;
    }
}
