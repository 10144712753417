@import '../../assets/styles/breackpoints.scss';
@import '../../assets/styles/variables.scss';

.tariffs {
    height: 100vh;
    overflow: hidden;

    background: #fff;

    &__wrapper {
        margin-left: 9%;
        margin-right: 7%;
        margin-top: 3%;
    }

    &__content {
        height: 88vh;
        display: flex;
        flex-direction: column;
    }

    &__header {
        color: #242424;
        font-family: Raleway;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 5%;
    }

    &__cards {
        display: flex;
        align-items: center;
        gap: 50px;

        margin-bottom: 23px;
    }

    &__card {
        border-radius: 25.756px;
        background: #fbfbfb;

        height: 230px;
        width: 390px;
        display: flex;
        flex-direction: column;

        padding: 32px;
    }

    &__card-title {
        color: #242424;
        font-family: Inter;
        font-size: 25.756px;
        font-style: normal;
        font-weight: 700;
        line-height: 28.976px;

        margin-bottom: 19px;
    }

    &__card-subtitle {
        color: #000;
        font-family: Inter;
        font-size: 20.39px;
        font-style: normal;
        font-weight: 500;
        line-height: 28.976px;
    }

    &__card-button {
        width: 100%;
        margin-top: auto;
        margin-bottom: 0;

        .button__text {
            color: $main-green;
        }
    }

    &__grey-text {
        display: block;
        color: #8a8a8a;
        font-family: Inter;
        font-size: 15.024px;
        font-style: normal;
        font-weight: 500;
        line-height: 28.976px;

        padding-left: 32px;
    }

    &__black-text {
        display: block;
        color: #000;
        font-family: Inter;
        font-size: 15.024px;
        font-style: normal;
        font-weight: 500;
        line-height: 28.976px;

        padding-left: 32px;
        margin-bottom: 50px;
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 95px;
        position: relative;
        bottom: 0;

        margin-top: auto;
        margin-bottom: 1%;

        a:hover {
            opacity: 0.6;
        }
    }

    &__footer-text {
        display: block;
        color: #242424;
        font-family: Raleway;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 35px;
    }

    &__footer-phone {
        font-family: Inter;
        font-size: 30px !important;
    }

    &__footer-signature {
        display: block;
        color: #000;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        margin-top: 20px;

        a {
            color: $main-green;
        }
    }
}

@media screen and (max-width: 1680px) and (min-width: 768px) {
    .tariffs {
        &__header {
            margin-bottom: 3%;
        }

        &__cards {
            gap: 40px;

            margin-bottom: 18px;
        }

        &__card {
            min-height: 240px;
            display: flex;
            flex-direction: column;

            padding: 32px;
        }
    }
}

@media screen and (max-width: 1480px) and (min-width: 768px) {
    .tariffs {
        &__header {
            font-size: 32px;
        }

        &__card {
            min-width: 250px;
            padding: 20px;
        }

        &__card-title {
            font-size: 18px;
            line-height: 25px;

            margin-bottom: 20px;
        }

        &__card-subtitle {
            font-size: 15px;
            line-height: 25px;
        }

        &__card-button {
            width: 100%;
            margin-top: auto;
            margin-bottom: 0;

            .button__text {
                color: $main-green;
            }
        }

        &__grey-text {
            font-size: 13px;

            padding-left: 30px;
        }

        &__black-text {
            font-size: 13px;

            padding-left: 30px;
        }

        &__footer {
            gap: 60px;
        }

        &__footer-text {
            font-size: 32px;

            margin-bottom: 20px;
        }

        &__footer-phone {
            font-size: 25px !important;
        }

        &__footer-signature {
            font-size: 13px;

            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1231px) and (min-width: 768px) {
    .tariffs {
        &__cards {
            margin-bottom: 15px;
        }

        &__card {
            min-height: 180px;
            height: 180px;
            padding: 16px;
        }

        &__card-title {
            font-size: 18px;

            margin-bottom: 15px;
        }

        &__card-subtitle {
            font-size: 15px;
        }

        &__footer-text {
            font-size: 28px;

            margin-bottom: 15px;
        }

        &__footer-phone {
            font-size: 20px !important;
        }

        &__footer-signature {
            font-size: 12px;

            margin-top: 15px;
        }

        &__grey-text {
            padding-left: 0;
        }

        &__black-text {
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 1056px) {
    .tariffs {
        height: auto;

        &__wrapper {
            margin-bottom: 2%;
        }

        &__cards {
            gap: 20px;
            margin-bottom: 10px;
        }

        &__card {
            width: fit-content;
            min-height: 170px;
            padding: 16px;
        }

        &__card-title {
            font-size: 18px;

            margin-bottom: 8px;
        }

        &__card-subtitle {
            font-size: 15px;
        }

        &__footer-text {
            font-size: 20px;

            margin-bottom: 10px;
        }

        &__footer-phone {
            font-size: 20px !important;
        }

        &__footer-signature {
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 992px) {
    .tariffs {
        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &__cards {
            flex-direction: column;
            align-items: center;
        }

        &__card {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .tariffs {
        &__wrapper {
            margin-left: 15px;
            margin-right: 25px;
            margin-top: 44px;
            margin-bottom: 77px;
        }

        &__content {
            height: 100%;
        }

        &__header {
            font-size: 26px;

            margin-bottom: 14px;
        }

        &__cards {
            display: flex;
            flex-direction: column;
            gap: 16px;

            margin-bottom: 20px;
        }

        &__card {
            border-radius: 15px;
            min-height: 150px;
            width: 100%;
            height: auto;

            padding: 24px;
        }

        &__card-title {
            font-size: 16px;
            line-height: 16px;

            margin-bottom: 8px;
        }

        &__card-subtitle {
            font-size: 14px;
            font-weight: 500;
            line-height: normal;

            margin-bottom: 24px;
        }

        &__card-button {
            width: 100%;
            margin-top: auto;
            margin-bottom: 0;

            .button .button__text {
                display: flex;
                justify-content: center;
                padding: 9px 0;
            }
        }

        &__grey-text {
            font-size: 13px;
            line-height: 17px;

            padding-left: 0;
        }

        &__black-text {
            font-size: 13px;
            line-height: 17px;

            padding-left: 0;
            margin-bottom: 75px;
        }

        &__footer {
            flex-direction: column;
            gap: 0;
        }

        &__footer-text {
            font-size: 26px;

            margin-bottom: 28px;
        }

        &__footer-phone {
            font-size: 22px !important;
        }

        &__footer-signature {
            font-size: 13px;

            margin-top: 25px;
        }

        &__footer-icon {
            width: 21px;
            height: 21px;
        }
    }
}
