.field {
    &__input {
        color: #242424;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        position: relative;
        width: 100%;
        min-width: 210px;
        min-height: 60px;
        border-radius: 8px;
        border: 2px solid #f7f2d5;
        background: #fff;

        padding: 18px 24px 18px 55px;

        &::placeholder {
            color: #242424;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &:focus {
            border: 3px solid #f5eec9;
            outline: none;
        }

        &:disabled {
            opacity: none;
            pointer-events: none;
        }

        &--error {
            border: 2px solid #dd8c8c;
            outline: none;
        }
    }

    &__icon {
        position: absolute;
        width: 20px;
        height: 20px;
        z-index: 1;
        margin-top: 20px;
        margin-left: 24px;
    }

    &__caption {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        color: #dd8c8c;
        text-align: center;
        font-size: 14px;
        font-family: Montserrat;

        line-height: 16px;

        padding-left: 15px;
        padding-top: 3px;
    }
}

@media screen and (max-width: 500px) {
    .field {
        &__input {
            font-size: 14px;

            min-width: 0;
            min-height: 0;

            padding: 16px 18px 16px 50px;

            &::placeholder {
                font-size: 14px;
            }
        }

        &__icon {
            width: 18px;
            height: 18px;
            margin-top: 16px;
            margin-left: 18px;
        }

        &__caption {
            font-size: 12px;
            line-height: 14px;

            padding-left: 15px;
            padding-top: 2px;
        }
    }
}
