@import '../../assets/styles/breackpoints.scss';

.reviews {
    height: 100vh;
    overflow: hidden;

    background: radial-gradient(
            137.99% 180.65% at 60.79% -38.63%,
            rgba(140, 190, 38, 0.05) 0%,
            rgba(140, 190, 38, 0.05) 100%
        ),
        linear-gradient(
            101deg,
            rgba(203, 245, 118, 0.1) 0%,
            rgba(236, 255, 197, 0) 80.9%
        );

    &__wrapper {
        margin-left: 9%;
        margin-right: 7%;
        margin-top: 10%;
    }

    &__header {
        width: 45%;

        color: #242424;
        font-family: Raleway;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    &__image {
        width: 100%;
        height: auto;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1680px) and (min-width: 768px) {
    .reviews {
        &__wrapper {
            margin-top: 9%;
        }

        &__image {
            width: 90%;
        }
    }
}
@media screen and (max-width: 1480px) and (min-width: 768px) {
    .reviews {
        &__wrapper {
            margin-top: 10%;
        }

        &__header {
            width: 45%;

            font-size: 32px;
        }

        &__image {
            width: 85%;
        }
    }
}
@media screen and (max-width: 1231px) and (min-width: 768px) {
}

@media screen and (max-width: 1056px) {
    .reviews {
        height: auto;

        &__image {
            width: 100%;
        }
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
    .reviews {
        &__wrapper {
            margin-left: 25px;
            margin-right: 25px;
            margin-top: 40px;
            margin-bottom: 32px;
        }

        &__header {
            width: 100%;
            font-size: 26px;

            margin-bottom: 20px;
        }
    }
}
