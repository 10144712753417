#menuToggle {
    display: block;
    min-width: 35px;
}

#menuToggle a {
    text-decoration: none;
    color: #fff;
}

#menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;

    cursor: pointer;

    opacity: 0;
    z-index: 2;
}

#menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #8cbe26;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh + 20px);
    margin-top: -20px;
    padding: 45px 23px 50px 75px;

    background: #8cbe26;

    list-style-type: none;
    -webkit-font-smoothing: antialiased;

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu ul {
    position: absolute;
    top: 40%;
}

.three {
    opacity: 0 !important;
}

.close {
    #menu {
        transform: translate(-100%, 0) !important;
    }
}

.open {
    #menu {
        transform: none !important;
    }

    span {
        background: #fff !important;
    }

    .one {
        opacity: 1;
        transform: rotate(45deg) translate(-3px, -5px) !important;
    }

    .three {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    .two {
        opacity: 1;
        transform: rotate(-45deg) translate(-3px, 1px) !important;
    }
}

.menu__header {
    display: flex;
    justify-content: space-between;

    .button {
        border-color: #fff;

        .button__text {
            color: #fff;
            padding: 9px 12px;
        }
    }
}

.menu__bottom {
    position: absolute;
    top: 90%;
}
