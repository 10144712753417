@import '../../../assets/styles/variables.scss';

.contacts {
    --contacts-align-items: flex-start;
    --contacts-gap: 31px;
    --contacts-margin: 40px;

    display: flex;
    justify-content: flex-start;

    align-items: var(--contacts-align-items);
    gap: var(--contacts-gap);
    margin-bottom: var(--contacts-margin);

    @media screen and (max-width: 979px) {
        button {
            font-size: 1.2rem;
            line-height: 1.5rem;
            height: 36px;
            width: 100%;
        }
    }

    @media screen and (min-width: 980px) {
        --contacts-align-items: center;
        --contacts-gap: 36px;
        --contacts-margin: 0;
    }

    &Text {
        --contacts-gap: 9px;

        display: flex;
        flex-direction: column;
        gap: var(--contacts-gap);
        width: 100%;
    }

    &Name {
        color: $main-green;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 16px;
        line-height: 1.1875;

        @media screen and (min-width: 980px) {
            font-size: 26px;
        }
    }

    &Role {
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans,
        Helvetica Neue, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.214;
        color: #000000;

        @media screen and (min-width: 980px) {
            font-size: 20px;
        }
    }

    &Contacts {
        --contacts-gap: 4px;

        display: flex;
        flex-direction: column;
        gap: var(--contacts-gap);
        font-size: 12px;

        a {
            color: currentColor;

            &:hover {
                color: $main-green;
            }
        }

        @media screen and (min-width: 980px) {
            --contacts-gap: 6px;

            font-size: 14px;
        }
    }

    &Photo {
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 50%;
        object-fit: contain;
        overflow: hidden;

        img {

            border-radius: 50%;
            object-fit: contain;
        }
    }
}
