@import '../../assets/styles/breackpoints.scss';

.waiters {
    height: 100vh;
    overflow: hidden;

    background: linear-gradient(
        163.25deg,
        rgba(149, 190, 65, 0.09) 5.09%,
        rgba(149, 190, 65, 0) 55.96%
    );

    background-image: url('../../assets/images/green-arrow2.svg');
    background-size: 85% 78%;
    background-position: top right;
    background-repeat: no-repeat;

    &__wrapper {
        margin-left: 9%;
        margin-right: 7%;
        margin-top: 15%;
    }

    &__content {
        display: grid;
        align-items: center;
        column-gap: 45px;
        row-gap: 180px;

        grid-template-columns: 45% 60%;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
            'header image'
            'info image';
    }

    &__header {
        grid-area: header;
        align-self: center;

        &-title {
            color: #242424;
            font-family: Raleway;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            margin-bottom: 30px;
        }
    }

    &__info {
        grid-area: info;
        max-width: 80%;

        border-radius: 5px;
        border: 2px solid #8cbe26;
        background: #fff;

        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        padding: 45px 18px 20px 20px;

        span {
            display: block;
        }

        &-image {
            margin-top: -120px;
        }
    }

    &__image {
        grid-area: image;
    }
}

@media screen and (max-width: 1680px) and (min-width: 768px) {
    .waiters {
        &__wrapper {
            margin-top: 12%;
        }
    }
}

@media screen and (max-width: 1480px) and (min-width: 768px) {
    .waiters {
        &__content {
            display: grid;
            align-items: center;
            column-gap: 45px;
            row-gap: 100px;

            grid-template-columns: 35% 60%;
        }

        &__header {
            &-title {
                font-size: 32px;

                margin-bottom: 20px;
            }
        }

        &__info {
            max-width: unset;
            font-size: 13px;

            padding: 20px 18px 20px 20px;

            &-image {
                display: none;
            }
        }

        &__image {
            width: 90%;
            height: auto;
        }
    }
}

@media screen and (max-width: 1231px) and (min-width: 768px) {
    .waiters {
        background-size: 88% 70%;
        &__content {
            column-gap: 25px;
            row-gap: 50px;
        }
    }
}

@media screen and (max-width: 1056px) {
    .waiters {
        height: auto;
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
    .waiters {
        background-image: none;

        &__wrapper {
            margin-left: 25px;
            margin-right: 25px;
            margin-top: 38px;
        }

        &__content {
            column-gap: 0;
            row-gap: 0;

            grid-template-columns: auto;
            grid-template-rows: repeat(3, auto);
            grid-template-areas:
                'header'
                'image'
                'info';
        }

        &__header {
            align-self: self-start;
            width: 95%;

            margin-bottom: 26px;

            &-title {
                font-size: 26px;

                margin-bottom: 16px;
            }
        }

        &__info {
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            max-width: unset;

            padding: 16px;
            margin: 0 -8px;

            &-image {
                display: none;
            }
        }

        &__image {
            margin: 0 -20px 23px -20px;
            width: 110%;
            height: auto;
        }
    }
}
