@import '../../assets/styles/variables.scss';

.request-modal {
    ::-webkit-scrollbar {
        background-color: #fff;
        width: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #8cbe26;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 57px;
        height: 100%;
        overflow-y: auto;

        border-radius: 25px;
        background: #fff;
    }

    &__wrapper-image {
        position: relative;
        width: 100%;
        height: auto;
        text-align: center;

        background-color: #8cbe26;
    }

    &__logo {
        position: absolute;
        top: 30px;
        left: 40px;
    }

    &__image {
        width: 50%;
    }

    &__group-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        padding: 0 85px;
        margin-bottom: 36px;
    }

    &__group-form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    &__title {
        color: #242424;
        text-align: center;
        font-family: Raleway;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 36px;
    }

    &__input {
        margin-bottom: 16px;
    }

    &__loading {
        margin: 0 auto;
    }

    &__button {
        width: 100%;

        margin-top: 24px;
        margin-bottom: 17px;
    }

    &__text {
        width: 400px;
        color: #1d1d1d;
        font-family: Montserrat;
        font-size: 20px;
        line-height: normal;
        line-height: 30px;
        text-align: center;

        margin: 0 auto 36px auto;
    }

    &__small-text {
        color: #1d1d1d;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;

        margin: 0 auto;
    }

    a {
        color: #ff6b00;
    }

    a:hover {
        opacity: 0.6;
    }
}

.success {
    margin: 0 50px 55px;
}

@media screen and (max-width: 800px) {
    .request-modal {
        height: 100vh;

        &__content {
            height: inherit;
            gap: 37px;
            border-radius: 0;
        }

        &__wrapper-image {
            border-radius: 0;
        }

        &__logo {
            position: absolute;
            top: 25px;
            left: 43%;
        }

        &__image {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 600px) {
    .request-modal {
        &__image {
            margin-top: 40px;
        }
    }
}

@media screen and (max-width: 540px) {
    .request-modal {
        &__logo {
            width: 65px;
            height: 57px;
        }

        &__image {
            margin-top: 45px;
        }

        &__group-content {
            padding: 0 24px;
            margin-bottom: 10px;
        }

        &__title {
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            margin-bottom: 29px;
        }

        &__button {
            width: 100%;

            margin-top: 20px;
            margin-bottom: 20px;

            .button__text {
                font-size: 14px;
                line-height: normal;

                padding: 16px 18px;
            }
        }

        &__text {
            width: 400px;
            color: #1d1d1d;
            font-family: Montserrat;
            font-size: 20px;
            line-height: normal;
            line-height: 30px;
            text-align: center;

            margin: 0 auto 30px auto;
        }

        &__small-text {
            color: #1d1d1d;
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;

            margin: 0 auto;
        }
    }
}
