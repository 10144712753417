@import '../../assets/styles/breackpoints.scss';

.main-section {
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(
        163.25deg,
        rgba(149, 190, 65, 0.09) 5.09%,
        rgba(149, 190, 65, 0) 55.96%
    );

    background-image: url('../../assets/images/green-arrow.svg');
    background-size: 90% 80%;
    background-position: top right;
    background-repeat: no-repeat;

    transition: all 0.2s;

    &__wrapper {
        display: flex;
        align-items: stretch;
        gap: 50px;

        padding: 10% 30px 0;
    }

    &__content {
        max-width: 404px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        margin-left: 9%;
    }

    &__wrapper-image {
        width: 55%;
    }

    &__image {
        width: 100%;
        height: auto;
        margin-top: -15px;
    }

    &__text {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;

        padding-bottom: 15px;
    }
}

.content {
    &__product {
        display: flex;
        align-items: center;
        gap: 10px;

        margin-bottom: 32px;
    }

    &__header {
        font-size: 43px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;

        margin-bottom: 11px;
    }

    &__subtitle {
        color: #565656;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;

        margin-bottom: 30px;
    }
}

.product {
    &__image {
        width: 32px;
        height: 32px;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;

        color: #6c9616;
    }
}

@media screen and (max-width: 1400px) {
    .main-section {
        &__wrapper {
            gap: 30px;
            padding-top: 11%;
        }

        &__wrapper-image {
            width: 50%;
        }

        &__content {
            margin-left: 5%;
        }

        &__text {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 1231px) {
    .main-section {
        background-size: 80% 90%;
        &__wrapper {
            gap: 0;
        }

        &__content {
            max-width: 45%;
        }

        &__text {
            margin-top: 20px;
        }
    }

    .content {
        &__product {
            margin-bottom: 20px;
        }

        &__subtitle {
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: 1056px) {
    .main-section {
        height: auto;
        overflow-y: auto;
        background-image: none;

        &__wrapper {
            padding-top: 15%;
        }

        &__content {
            max-width: unset;
        }

        &__wrapper-image {
            display: none;
        }

        &__image {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .main-section {
        background-image: none;
        &__wrapper {
            flex-direction: column-reverse;
            gap: 20px;

            padding: 20px 0;
            margin-top: 90px;
            margin-left: 0;
        }

        &__content {
            margin-left: 0;
            padding: 0 35px 0 25px;
        }

        &__text {
            display: none;
            margin-top: 0;
        }

        &__wrapper-image {
            display: block;
            width: 100%;
        }

        &__image {
            display: block;
        }
    }

    .content {
        &__product {
            gap: 8px;

            margin-bottom: 22px;
        }

        &__header {
            font-size: 32px;
            line-height: normal;

            margin-bottom: 9px;
        }

        &__subtitle {
            font-size: 23px;
            line-height: normal;

            margin-bottom: 22px;
        }

        &__button {
            .button__text {
                padding: 15px 24px;
            }
        }
    }
}
