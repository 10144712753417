body {
    font-family: Raleway;
    position: relative;
    height: 100vh !important;
    width: 100vw !important;
    overflow-x: hidden;
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

img {
    vertical-align: top;
}

li {
    list-style-type: none;
}
ul {
    margin-left: 0;
    padding-left: 0;
}

.fp-watermark {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
}

#fp-nav ul li a span {
    height: 10px !important;
    width: 10px !important;
    margin: 0 !important;
    background: #24242436 !important;
}

.dark-style #fp-nav ul li a span {
    background: #575358 !important;
}

#fp-nav ul li a.active span {
    background-color: #8cbe26 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
