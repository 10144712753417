.button {
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    white-space: nowrap;
    transition: 0.2s all;

    &__text {
        font-family: Montserrat;
        text-align: center;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0em;
        font-weight: 500;
        padding: 14px 18px;
    }

    &:disabled {
        opacity: 50%;
        cursor: not-allowed;
    }

    &__image {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;

        margin-left: 24px;
    }

    &__image + &__text {
        padding-left: 10px !important;
    }
}

//зеленая кнопка без фона
.button--transparent-green {
    background: transparent;
    border: 2px solid #8cbe26;
    cursor: pointer;

    .button__text {
        color: #242424;
    }

    &:hover {
        background: #8cbe26;

        .button__text {
            color: #fff;
        }
    }

    &:active {
        outline: none;
        background: #9ad12d;
        border: 2px solid #9ad12d;

        .button__text {
            color: #fff;
        }
    }
}

//зеленая кнока
.button--green {
    background: #8cbe26;
    border: none;
    outline: none;
    cursor: pointer;

    .button__text {
        color: #fff;
        font-size: 16px;
        line-height: 19.5px;
        font-weight: 600;
        padding: 18px 24px;
    }

    &:hover {
        background: #85b32a;
    }

    &:active {
        background: #9ad12d;
    }
}
