.main {
    position: relative;
}

.__wrapper {
    max-width: 1700px;

    margin: 0 auto;
    box-sizing: content-box;
}
