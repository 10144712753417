@import '../../assets/styles/breackpoints.scss';

.price {
    height: 100vh;
    overflow: hidden;

    background: linear-gradient(
        163.25deg,
        rgba(149, 190, 65, 0.09) 5.09%,
        rgba(149, 190, 65, 0) 55.96%
    );

    background-image: url('../../assets/images/green-arrow.svg');
    background-size: 88% 70%;
    background-position: top right;
    background-repeat: no-repeat;

    &__wrapper {
        margin-left: 9%;
        margin-right: 7%;
        margin-top: 9%;
    }

    &__content {
        display: grid;
        gap: 70px;

        grid-template-columns: auto auto;
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
            'header qr'
            'header image'
            'header image'
            'info image';
    }

    &__header {
        grid-area: header;
        align-self: center;
        width: 80%;

        &-title {
            color: #242424;
            font-family: Raleway;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            margin-bottom: 30px;
        }

        &-button {
        }
    }

    &__info {
        grid-area: info;

        width: 80%;
        border-radius: 5px;
        border: 2px solid #8cbe26;
        background: #fff;

        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        padding: 35px 24px 20px 24px;

        span {
            display: block;
        }

        &-image {
            margin-top: -120px;
        }
    }

    &__qr {
        grid-area: qr;
        justify-self: end;
        margin-right: 10%;

        display: flex;
        gap: 10px;
        align-items: center;
        width: 40%;

        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
    }

    &__image {
        grid-area: image;
    }
}

@media screen and (max-width: 1680px) and (min-width: 768px) {
}

@media screen and (max-width: 1480px) and (min-width: 768px) {
    .price {
        background-size: 78% 80%;
        &__wrapper {
            margin-top: 10%;
        }

        &__content {
            gap: 40px;
        }

        &__qr {
            width: unset;
        }

        &__header {
            width: 85%;

            &-title {
                font-size: 32px;

                margin-bottom: 25px;
            }
        }

        &__info {
            font-size: 13px;

            padding: 20px;

            &-image {
                display: none;
            }
        }

        &__image {
            width: 110%;
            height: auto;
        }
    }
}
@media screen and (max-width: 1231px) and (min-width: 768px) {
    .price {
        background-size: 68% 75%;
        &__wrapper {
            margin-top: 10%;
        }

        &__content {
            gap: 0;
        }

        &__qr {
            width: unset;

            img {
                width: 70px;
                height: 70px;
            }
        }

        &__header {
            width: 90%;

            &-title {
                font-size: 32px;
                margin-bottom: 20px;
            }
        }

        &__info {
            width: 85%;

            font-size: 13px;

            padding: 20px;

            &-image {
                display: none;
            }
        }

        &__image {
            width: 110%;
            height: auto;

            margin-top: 25px;
        }
    }
}

@media screen and (max-width: 1056px) {
    .price {
        height: 100%;
        background-image: none;

        margin-bottom: 5%;

        &__wrapper {
            margin-top: 10%;
        }

        &__content {
            gap: 0;
        }

        &__qr {
            width: unset;

            img {
                width: 60px;
                height: 60px;
            }
        }

        &__header {
            width: 90%;

            margin-bottom: 20px;

            &-title {
                font-size: 28px;
                margin-bottom: 20px;
            }
        }

        &__info {
            width: 90%;

            font-size: 13px;

            padding: 16px;

            &-image {
                display: none;
            }
        }

        &__image {
            width: 110%;
            height: auto;

            margin-top: 25px;
        }
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
    .price {
        &__wrapper {
            margin-left: 25px;
            margin-right: 25px;
            margin-top: 33px;
            margin-bottom: 40px;
        }

        &__content {
            gap: 0px;

            grid-template-columns: auto;
            grid-template-rows: repeat(3, auto);
            grid-template-areas:
                'header'
                'image'
                'info';
        }

        &__header {
            grid-area: header;
            align-self: self-start;

            &-title {
                font-size: 26px;
                margin-bottom: 16px;
            }
        }

        &__info {
            width: 100%;

            font-size: 13px;
            font-weight: 400;
            line-height: normal;

            padding: 16px;

            &-image {
                display: none;
            }
        }

        &__qr {
            display: none;
        }

        &__image {
            width: 118%;
            height: auto;

            margin: 19px -10px 24px -10px;
        }
    }
}
