@import '../../assets/styles/breackpoints.scss';
@import '../../assets/styles/variables.scss';

.mailing {
    height: 100vh;
    overflow: hidden;

    background: radial-gradient(
            137.99% 180.65% at 60.79% -38.63%,
            rgba(140, 190, 38, 0.05) 0%,
            rgba(140, 190, 38, 0.05) 100%
        ),
        linear-gradient(
            101deg,
            rgba(203, 245, 118, 0.1) 0%,
            rgba(236, 255, 197, 0) 80.9%
        );

    &__wrapper {
        margin-left: 9%;
        margin-right: 7%;
        margin-top: 9%;
    }

    &__content {
        display: grid;
        column-gap: 70px;
        row-gap: 0;

        grid-template-columns: 40% 60%;
        grid-template-rows: repeat(4, fit-content);
        grid-template-areas:
            'header image'
            'header image'
            'header image'
            'info cards';
    }

    &__header {
        grid-area: header;
        align-self: center;
        width: 60%;

        &-wrapper-title {
            display: flex;
            align-items: start;
            gap: 19px;

            margin-bottom: 32px;
        }

        &-title {
            color: #242424;
            font-family: Raleway;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        &-image {
            width: 39px;
            height: 39px;
        }
    }

    &__info {
        grid-area: info;

        border-radius: 5px;
        border: 2px solid #8cbe26;
        background: #fff;

        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        padding: 45px 18px 20px 20px;

        span {
            display: block;
        }

        &-image {
            margin-top: -80px;
        }
    }

    &__image {
        grid-area: image;
        display: flex;
        align-items: end;
        justify-content: center;

        &-one {
            margin-bottom: 5%;
        }
    }

    &__cards {
        grid-area: cards;
        display: flex;
        align-items: stretch;

        li {
            display: block;
            border: 2px solid #e1e1e1;
            background: #fff;

            padding: 42px 34px;
        }

        li:first-child {
            border-radius: 5px 0 0 5px;
            border-right: none;
        }

        li:last-child {
            border-radius: 0 5px 5px 0;
            border-left: none;
        }

        &-title {
            color: $main-green;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 27px;

            margin-bottom: 6px;
        }

        &-text {
            display: block;
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }
}

@media screen and (max-width: 1680px) and (min-width: 768px) {
    .mailing {
        &__wrapper {
            margin-top: 8%;
        }
    }
}
@media screen and (max-width: 1480px) and (min-width: 768px) {
    .mailing {
        &__content {
            column-gap: 40px;

            grid-template-columns: 35% 60%;
        }

        &__header {
            width: 75%;
            margin-bottom: 20px;

            &-wrapper-title {
                display: flex;
                align-items: start;
                gap: 19px;

                margin-bottom: 20px;
            }

            &-title {
                font-size: 32px;
                margin-bottom: 0;
            }
        }

        &__info {
            font-size: 13px;

            padding: 20px;

            &-image {
                display: none;
            }
        }

        &__image {
            grid-area: image;
            display: flex;
            align-items: end;
            justify-content: center;

            &-one {
                max-width: 35%;

                margin-bottom: 5%;
            }

            &-two {
                width: 55%;
            }
        }

        &__cards {
            li {
                padding: 20px;
            }

            &-text {
                font-size: 13px;
            }
        }
    }
}

@media screen and (max-width: 1056px) {
    .mailing {
        height: auto;

        &__wrapper {
            margin-bottom: 5%;
        }
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
    .mailing {
        &__wrapper {
            margin-left: 25px;
            margin-right: 25px;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        &__content {
            display: grid;
            column-gap: 0;
            row-gap: 0;

            grid-template-columns: auto;
            grid-template-rows: repeat(4, auto);
            grid-template-areas:
                'header'
                'image'
                'cards'
                'info';
        }

        &__header {
            align-self: self-start;
            width: 100%;

            &-wrapper-title {
                align-items: start;
                gap: 16px;

                margin-bottom: 16px;
            }

            &-title {
                font-size: 26px;
                width: 65%;
            }

            &-image {
                width: 39px;
                height: 39px;
            }
        }

        &__info {
            font-size: 13px;
            font-weight: 400;
            line-height: normal;

            padding: 16px;

            &-image {
                display: none;
            }
        }

        &__image {
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            &-one {
                width: 90%;
                margin-top: 36px;
                margin-bottom: 20px;
            }

            &-two {
                width: 90%;
                margin: 0 auto;
            }
        }

        &__cards {
            flex-direction: column;
            align-items: center;

            margin: 0 -8px 22px -8px;

            li {
                display: block;
                border: 2px solid #e1e1e1;
                width: 100%;

                padding: 16px;
            }

            li:first-child {
                border-radius: 0;
                border-right: 2px solid #e1e1e1;
                border-bottom: none;
            }

            li:last-child {
                border-radius: 0;
                border-left: 2px solid #e1e1e1;
                border-top: none;
            }
        }
    }
}
