.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    overflow-y: auto;
    overflow-x: hidden;

    &-host {
        overflow: hidden;
        height: 90%;
        max-height: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
    }

    &__button-close {
        background: transparent;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 17px;
        margin-right: 18px;
        width: 39px;
        height: 39px;
        cursor: pointer;
        z-index: 1;

        border: none;
        outline: none;
    }

    &.modal--active {
        opacity: 1;
        z-index: 100;
        pointer-events: all;
    }

    &__content {
        height: 100%;
        overflow: hidden;
        border-radius: 25px;
        transform: scale(0.5);
        transition: 0.4s all;

        &.modal__content--active {
            transform: scale(1);
        }
    }
}

.modal--mobile {
    .modal__content {
        width: 100vw;
        height: 100vh;
    }
}

@media screen and (max-width: 800px) {
    .modal {
        &-host {
            width: 100%;
            height: 100%;
            max-height: unset;
            border-radius: unset;
        }

        &__content {
            border-radius: unset;
        }
    }
}
