.header {
    position: absolute;
    width: 100%;
    top: 30px;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;

    &__group {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__container {
        width: 100%;
        max-width: 1700px;
        min-height: 100px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0 30px;
    }

    &__menu {
        margin: 0 15px;
    }
}

.menu {
    &__list {
        display: flex;
        gap: 43px;
    }

    &__item {
        width: 100%;
        color: #242424;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
    }

    &__link {
        color: #242424;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.1s;

        &:hover {
            color: #6c9616;
            border-bottom: 2px solid #6c9616;
        }

        &:active {
            color: #81b815;
            border-bottom: 2px solid #81b815;
        }
    }
}

.header--fixed {
    position: fixed;
    top: 0 !important;
    max-height: 90px;

    background: #fff;
}

@media screen and (max-width: 1400px) and (min-width: 500px) {
    .header {
        top: 15px;
    }
}

@media screen and (max-width: 800px) {
    .menu {
        &__list {
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;
        }
    }
}

@media screen and (max-width: 500px) {
    .header {
        &__container {
            width: 100%;
            max-width: 1700px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            margin: 0 25px;
        }

        &__image {
            width: 60px;
            height: 60px;
        }

        &__button {
            .button__text {
                padding: 9px 12px;
            }
        }

        .menu__item {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            margin-bottom: 35px;
        }

        .menu__link {
            &:hover {
                border-bottom: 2px solid #fff;
            }

            &:active {
                border-bottom: 2px solid #fff;
            }
        }
    }

    .header--fixed {
        max-height: 80px;
    }
}
